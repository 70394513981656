import { render, staticRenderFns } from "./InfinityPhoneNumber.vue?vue&type=template&id=37958831&scoped=true&"
import script from "./InfinityPhoneNumber.vue?vue&type=script&lang=ts&"
export * from "./InfinityPhoneNumber.vue?vue&type=script&lang=ts&"
var disposed = false

function injectStyles (context) {
  if (disposed) return
  
}


  module.hot && module.hot.dispose(function (data) {
    disposed = true
  })

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "37958831",
  null
  ,true
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/mfe-ca-tester/mfe-ca-tester/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('37958831')) {
      api.createRecord('37958831', component.options)
    } else {
      api.reload('37958831', component.options)
    }
    module.hot.accept("./InfinityPhoneNumber.vue?vue&type=template&id=37958831&scoped=true&", function () {
      api.rerender('37958831', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/utils/InfinityPhoneNumber.vue"
export default component.exports